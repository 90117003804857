import { makeAutoObservable, runInAction } from 'mobx';

import { contentsStart, contentsStop } from '../../services/apis/ContentsApi';

import KorToEn from '../../utils/KorToEn';
import * as Cookie from '../../utils/Cookie';

const DOWNLOAD_TIMOUT = 10;

class PlayerControlStore {
	rootStore;

	roles = {};
	executionUuid = null;

	isStart = false;

	isLoading = false;

	isDownloading = false;
	isPortalReady = false;
	downloadTimout = DOWNLOAD_TIMOUT;
	downloadProgress = 0;

	constructor(root) {
		makeAutoObservable(this);
		this.rootStore = root;
	}

	// isReadySetting() {
	// 	return (
	// 		this.rootStore.mqttStore.likuHeartBeat?.isHeartBeat &&
	// 		this.rootStore.likuStore.thisLiku?.language &&
	// 		this.rootStore.likuStore.thisLiku?.volume
	// 	);
	// }

	getIsStart() {
		return this.isStart;
	}

	getIsLoading() {
		return this.isLoading;
	}

	async playerReady(role) {
		console.log('playerReady', role);
		// this.rootStore.mqttStore.likuMqtt.getAllStatus();
		this.setInitRole(role);

		const liku = this.rootStore.likuStore.thisLiku;
		if (liku) {
			await new Promise((resolve) => {
				const isGetLanguage = () => {
					if (this.rootStore.likuStore.thisLiku?.language) {
						resolve();
					} else {
						setTimeout(isGetLanguage, 100);
					}
				};
				isGetLanguage();
			});

			console.log(
				'this.rootStore.likuStore.thisLiku?.language',
				this.rootStore.likuStore.thisLiku?.language,
			);

			if (this.rootStore.likuStore.thisLiku?.language !== 'ko') {
				this.rootStore.mqttStore.likuMqtt.setLanguage(
					'ko',
					this.setContentsConnect.bind(this),
				);
			} else {
				this.setContentsConnect();
			}

			//
			// console.log('metaverseSubscribe');
			// this.rootStore.mqttStore.playerMqtt.metaverseImageDB(
			// 	this.rootStore.birthdayStore.getMetaverseImageDB.bind(
			// 		this.rootStore.birthdayStore,
			// 	),
			// );
			//
			// this.rootStore.mqttStore.playerMqtt.metaverseWhole(
			// 	this.rootStore.birthdayStore.setWholeImg.bind(
			// 		this.rootStore.birthdayStore,
			// 	),
			// );
			//
			// this.rootStore.mqttStore.playerMqtt.metaverseImageUser(
			// 	this.rootStore.birthdayStore.setMemoryImg.bind(
			// 		this.rootStore.birthdayStore,
			// 	),
			// );
		}
	}

	setContentsConnect() {
		const role = this.roles[Object.keys(this.roles)[0]];
		console.log('role.connect', role?.connect);
		if (!role?.connect) {
			this.rootStore.mqttStore.playerMqtt.setContentsConnect(
				role,
				this.isConnect.bind(this),
			);
		}
	}

	setInitRole(data) {
		const removeRoles = Object.keys(this.roles).filter((role) =>
			data.some((newRole) => newRole.name === this.roles[role].name),
		);
		for (const role of removeRoles) {
			delete this.roles[role];
		}
		if (!Array.isArray(data)) return;
		for (const role of data) {
			console.log('role', role);
			const englishName = KorToEn(
				(typeof role === 'string' ? role : role?.name) || 'naraeisyeon',
			);
			const isInitFirstRole = !(englishName in this.roles);
			const isInitFirstLiku =
				this.roles[englishName]?.liku.uuid !==
				this.rootStore.likuStore.thisLiku?.uuid;

			if (isInitFirstRole || isInitFirstLiku) {
				this.roles[englishName] = {
					role: role.name,
					color: role.color,
					liku: {
						serial: this.rootStore.likuStore.thisLiku?.serial,
						uuid: this.rootStore.likuStore.thisLiku?.uuid,
					},
					connect: false,
					isNext: false,
					download: 0,
					join: false,
					voice: false,
					check: false,
				};
			} else {
				const tempRoles = { ...this.roles[englishName] };
				this.roles[englishName] = {
					...tempRoles,
					color: role.color,
					isNext: false,
					download: 0,
					join: false,
					voice: false,
					check: false,
				};
			}
		}
	}

	isConnect() {
		// console.log('isConnect', topic);
		if (this.roles && this.roles[Object.keys(this.roles)[0]]) {
			this.roles[Object.keys(this.roles)[0]].connect = true;
		}

		// const uuid = topic.split('/')[1];
		// const role = Object.keys(this.roles).find(
		// 	(role) => this.roles[role].likuUuid == uuid,
		// );

		// this.roles[role].connect = true;
	}

	async start(type) {
		if (!this.isStart && this.rootStore.likuStore.thisLiku?.uuid) {
			this.isStart = true;
			this.isLoading = true;

			const serials = Object.keys(this.roles).map((role) => [
				this.roles[role].liku.serial,
				this.roles[role].role,
			]);
			console.log('content', this.rootStore.videoStore.content);
			const contentsInfo = {
				title: this.rootStore.videoStore.content?.title,
				serials,
			};

			const uuid = await contentsStart(contentsInfo);
			// const uuid = this.rootStore.likuStore.thisLiku?.uuid;
			console.log('playerMqtt contentsStart', uuid);
			runInAction(() => {
				this.executionUuid = uuid;
			});

			const id = Cookie.get('id');
			this.rootStore.mqttStore.playerMqtt.setContentsMode(
				id,
				this.executionUuid,
				this.check.bind(this),
			);

			// await this.rootStore.mqttStore.playerMqtt.musicalSet();

			let timeout = 2;
			let retry = false;

			this.rootStore.mqttStore.playerMqtt.isContentsReady();

			const contentsReadyInterval = setInterval(async () => {
				const isNotJoin = Object.keys(this.roles).find(
					(role) => this.roles[role].join === false,
				);

				// isNotJoin === undefined
				if (!isNotJoin) {
					clearInterval(contentsReadyInterval);
				} else if (timeout > 0) {
					timeout -= 1;
				} else if (!retry) {
					retry = true;
					timeout = 2;

					this.rootStore.mqttStore.playerMqtt.isContentsReady();
					clearInterval(contentsReadyInterval);
				} else {
					// this.errorModal = true;
					clearInterval(contentsReadyInterval);
				}
			}, 1000);
		}
	}

	check(data) {
		const type = data.data;
		console.log('download', this.roles);
		if (!type) return;
		switch (type) {
			case 'join':
				this.checkJoin(data);
				break;
			case 'voice':
				this.checkDownloadAndVoice(data);
				break;
			case 'done':
				this.isNext(data);
				break;
			default:
				if (!isNaN(type)) {
					this.setDownloadProgress(data);
				}
				break;
		}
	}

	async checkJoin(data) {
		if (data.uuid === this.executionUuid) {
			console.log('download', this.roles);
			if (data.role in this.roles) {
				this.roles[data.role].join = true;
			}
			console.log('data.uuid', data.uuid);
			console.log('this.executionUuid', this.executionUuid);
			const isNotJoin = Object.keys(this.roles).find((key) => {
				console.log('download', this.roles[key].join);
				return this.roles[key].join === false;
			});
			if (!isNotJoin) {
				console.log('download Finish Join!!!!');
				this.rootStore.mqttStore.playerMqtt.contentsCheck(
					this.executionUuid,
					this.rootStore.videoStore.content.uuid,
				);

				this.checkDownloadAndVoice(data);
			}
		}
	}

	checkDownloadAndVoice(data) {
		if (data.uuid === this.executionUuid) {
			if (this.isDownloading) {
				if (data.role in this.roles) {
					this.roles[data.role].voice = true;
				}
			} else {
				this.isDownloading = true;

				let retry = false;
				const downloadingVoiceInterval = setInterval(() => {
					const isNotVoice = Object.keys(this.roles).find(
						(role) => this.roles[role].voice === false,
					);
					console.log('isNotVoice', isNotVoice);

					if (!isNotVoice) {
						console.log('Finish DownloadAndVoice!!!!');
						this.isDownloading = false;
						this.isLoading = false;
						clearInterval(downloadingVoiceInterval);
						this.play();
					} else if (this.downloadTimout > 0) {
						this.downloadTimout -= 1;
					} else if (!retry) {
						retry = true;
						this.downloadTimout = DOWNLOAD_TIMOUT;
						this.rootStore.mqttStore.playerMqtt.contentsCheck(
							this.executionUuid,
							this.rootStore.videoStore.content.uuid,
						);
					} else {
						// this.errorModal = true;
						clearInterval(downloadingVoiceInterval);
					}
				}, 1000);
			}
		}
	}

	setDownloadProgress(data) {
		console.log('setDownloadProgress', data.uuid, this.executionUuid);
		if (data.uuid === this.executionUuid) {
			console.log('setDownloadProgress!!!!');
			this.downloadTimout = DOWNLOAD_TIMOUT;

			if (data.role in this.roles) {
				this.roles[data.role].download = parseInt(data.data);
			}

			let sumDownload = 0;
			for (const role of Object.keys(this.roles)) {
				try {
					sumDownload += this.roles[role].download;
				} catch (e) {
					console.log('Error setDownloadProgress');
				}
			}

			this.downloadProgress = parseInt(
				(sumDownload / (Object.keys(this.roles).length * 100)) * 100,
			);
		}
	}

	setPortalReady(_ready) {
		runInAction(() => {
			this.portalReady = _ready;
		});
	}

	async isNext(data) {
		console.log(
			'this.rootStore.videoStore.playState.isPlay',
			this.rootStore.videoStore.playState.isPlay,
		);
		if (
			data.uuid === this.executionUuid &&
			this.rootStore.videoStore.playState.isPlay
		) {
			this.roles[data.role].isNext = true;

			const isNotNext = Object.keys(this.roles).find(
				(role) => this.roles[role].isNext === false,
			);

			if (!isNotNext) {
				const isInteractive = this.rootStore.videoStore.isInteractive();
				if (isInteractive) {
					console.log('isInteractive');
					await this.rootStore.interactiveStore.checkCondition();
					// console.log('aaaa');
					// if (isCondition) {
					// 	this.rootStore.playerControlStore.likuPlay();
					// }
				} else {
					this.rootStore.videoStore.setSceneNumber();
					this.rootStore.playerControlStore.likuPlay();
				}
			}
			this.setPortalReady(false);
		}
	}

	play() {
		console.log('contents Play!!!');
		this.rootStore.videoStore.togglePlay(false);
		const isInteractive = this.rootStore.videoStore.isInteractive();
		if (isInteractive) {
			console.log('isInteractive');
			this.rootStore.interactiveStore.getCurrentNode();
		} else {
			this.rootStore.videoStore.setSceneNumber();
			// this.likuPlay();
		}

		const isAruco = this.rootStore.videoStore.isAruco();
		if (isAruco) {
			this.rootStore.mqttStore.playerMqtt.setArucoMode(false);
		}
	}

	pause() {
		console.log('contents Pause!!!');
		this.rootStore.videoStore.togglePlay(true);

		const isAruco = this.rootStore.videoStore.isAruco();
		if (isAruco) {
			console.log('isAruco', this.rootStore.videoStore.content?.aruco);
			this.rootStore.mqttStore.playerMqtt.setArucoMode(
				this.rootStore.videoStore.content?.aruco,
			);
		} else {
			console.log('!isAruco stop');
			this.likuStop();
		}
	}

	stop() {
		console.log('contents Stop!!!');
		this.rootStore.videoStore.togglePlay(true);
		this.end();
	}

	likuPlay() {
		const scene = this.rootStore.videoStore?.content?.data;
		if (!scene) return;
		if (
			this.rootStore.videoStore.playState.isPlay &&
			scene?.length > this.rootStore.videoStore.sceneNumber
		) {
			try {
				let scene = this.rootStore.videoStore?.content?.data || [
					...this.rootStore.videoStore.content.nodes,
				];
				// console.log('likuPlay', scene, this.rootStore.videoStore.content);
				let curScene = scene[this.rootStore.videoStore.sceneNumber];
				if (!Array.isArray(curScene)) {
					curScene = [curScene];
				}

				for (let sceneMotions of curScene) {
					// for (let role of sceneMotions) {
					console.log('likuPlay', sceneMotions);
					// node로 묶인 컨텐츠의 경우 motion Data 위치
					if (sceneMotions?.type) {
						sceneMotions = sceneMotions.data;
					}

					const isBirthday = this.rootStore.videoStore.isBirthday();
					if (isBirthday) {
						const curTTS = sceneMotions.motion?.speech?.TTS;
						const transTTS = curTTS
							.replaceAll('[month]', new Date().getMonth() + 1)
							.replaceAll(
								'[names]',
								this.rootStore.birthdayStore.selectedChildList
									.map((child) => child.name)
									.join(',,,, '),
							);
						if (sceneMotions?.motion?.speech?.TTS) {
							sceneMotions.motion.speech.TTS = transTTS;
						}
					}

					const { role, motion } = JSON.parse(JSON.stringify(sceneMotions));

					const englishName = KorToEn(
						(typeof role === 'string' ? role : role?.name) || 'naraeisyeon',
					);
					const display = motion.display.display_name?.split('_');

					if (display.length > 1) {
						motion.display.display_name = display.join('_');
					} else {
						console.log('likuPlay role', this.roles);
						const displayColor = this.roles[englishName].color;
						if (displayColor !== 'yellow') {
							motion.display.display_name = `${displayColor}_${motion.display.display_name}`;
						}
					}

					this.rootStore.mqttStore?.playerMqtt.playMotion(
						this.executionUuid,
						englishName,
						motion,
					);
				}
			} catch (error) {
				console.log('likuPlay Error', error);
			}
		} else {
			this.stop();
		}
	}

	likuStop() {
		this.rootStore.mqttStore.playerMqtt.defaultStop();
	}

	async end() {
		if (this.isStart) {
			this.isStart = false;
			this.rootStore.videoStore.setSceneNumber(-1);

			const serials = Object.keys(this.roles).map((role) => [
				this.roles[role].liku.serial,
				this.roles[role].role,
			]);
			const contentsInfo = {
				title: this.rootStore.videoStore.content.title,
				uuid: this.executionUuid,
				serials,
			};
			await contentsStop(contentsInfo);

			const id = Cookie.get('id');
			this.rootStore.mqttStore.playerMqtt.unsetContentsMode(
				id,
				this.executionUuid,
			);

			const isMetaverse = this.rootStore.videoStore.isMetaverse();
			const isAruco = this.rootStore.videoStore.isAruco();

			if (isMetaverse) {
				this.rootStore.mqttStore.playerMqtt.unsetMetaverse();
			}
			if (isAruco) {
				this.rootStore.mqttStore.playerMqtt.setArucoMode();
			}
		}
	}

	setIsLoading(value) {
		this.isLoading = value;
	}

	clear() {
		this.end();
		this.rootStore.videoStore.clear();
		this.rootStore.interactiveStore.clear();

		this.roles = {};
		this.executionUuid = null;
		this.isStart = false;
		this.isLoading = false;
		this.isDownloading = false;
		this.isPortalReady = false;
		this.downloadProgress = 0;
	}
}

export default PlayerControlStore;
